import { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Table, DatePicker, Select, Card, Row, Col } from 'antd';
import { useAtom } from 'jotai';
import { themeAtom } from '@/atoms';
import { fetchDocumentsData } from '@/requests/dataService';

const { RangePicker } = DatePicker;

const DocumentsChart = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('Todas las Oficinas');
  const [theme] = useAtom(themeAtom);
  const [availableModels, setAvailableModels] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchDocumentsData();
        const dataWithKeys = result.map((item, index) => ({ ...item, key: index })); // Add unique key to each row
        setData(dataWithKeys);
        setFilteredData(dataWithKeys);
      } catch (error) {
        setError(error.message);
      }
    };

    getData();
  }, []);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    filterData(dates, selectedModel, selectedOffice);
    updateAvailableModels(filteredData, selectedOffice, dates);
  };

  const handleModelChange = (value) => {
    setSelectedModel(value);
    filterData(selectedDates, value, selectedOffice);
  };

  const handleOfficeChange = (value) => {
    setSelectedOffice(value);
    filterData(selectedDates, selectedModel, value);
    updateAvailableModels(filteredData, value, selectedDates);
  };

  const filterData = (dates, model, office) => {
    let filtered = data;

    if (dates && dates.length === 2) {
      const [start, end] = dates;
      filtered = filtered.filter(item => {
        const date = new Date(item['Fecha de Generacion v2']);
        return date >= start && date <= end;
      });
    }

    if (model) {
      filtered = filtered.filter(item => parseModel(item['code']) === model);
    }

    if (office && office !== 'Todas las Oficinas') {
      filtered = filtered.filter(item => item['office_name'] === office);
    }

    setFilteredData(filtered);
  };

  const parseModel = (code) => {
    return code.replace(/-?C\d+-?\d*/, '');
  };

  const updateAvailableModels = (data, office, dates) => {
    let filtered = data;

    if (dates && dates.length === 2) {
      const [start, end] = dates;
      filtered = filtered.filter(item => {
        const date = new Date(item['Fecha de Generacion v2']);
        return date >= start && date <= end;
      });
    }

    if (office && office !== 'Todas las Oficinas') {
      filtered = filtered.filter(item => item['office_name'] === office);
    }

    const uniqueModels = [...new Set(filtered.map(item => parseModel(item['code'])))];
    setAvailableModels(uniqueModels);
  };

  const aggregateByModel = (data) => {
    return data.reduce((acc, item) => {
      const model = parseModel(item['code']);
      if (!acc[model]) {
        acc[model] = { ...item, code: model, quantity: 0, totalAmount: 0, count: 0 };
      }
      acc[model].quantity += item['Quantity v2'];
      acc[model].totalAmount += item['Total Amount v2'];
      acc[model].count += 1;
      return acc;
    }, {});
  };

  const aggregateByOffice = (data) => {
    return data.reduce((acc, item) => {
      const office = item['office_name'];
      if (!acc[office]) {
        acc[office] = { office, quantity: 0, totalAmount: 0, count: 0 };
      }
      acc[office].quantity += item['Quantity v2'];
      acc[office].totalAmount += item['Total Amount v2'];
      acc[office].count += 1;
      return acc;
    }, {});
  };

  const aggregateByColorAndSize = (data) => {
    return data.reduce((acc, item) => {
      const color = item['code'].match(/C\d+/)?.[0] || 'N/A';
      const size = item['code'].match(/-\d+-?\d*/)?.[0].replace('-', '') || 'N/A';
      const key = `${color}-${size}`;

      if (!acc[key]) {
        acc[key] = { color, size, quantity: 0, totalAmount: 0, count: 0 };
      }
      acc[key].quantity += item['Quantity v2'];
      acc[key].totalAmount += item['Total Amount v2'];
      acc[key].count += 1;
      return acc;
    }, {});
  };

  const calculateTotals = (data) => {
    const totalQuantity = data.reduce((acc, item) => acc + item['Quantity v2'], 0);
    const totalAmount = data.reduce((acc, item) => acc + item['Total Amount v2'], 0);
    const totalDocuments = data.length;

    return { totalQuantity, totalAmount, totalDocuments };
  };

  const { totalQuantity, totalAmount, totalDocuments } = calculateTotals(filteredData);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'Fecha de Generacion v2',
      key: 'Fecha de Generacion v2',
      sorter: (a, b) => a['Fecha de Generacion v2'].localeCompare(b['Fecha de Generacion v2']),
    },
    {
      title: 'Model',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a, b) => a.quantity - b.quantity,
      render: (text) => Highcharts.numberFormat(text, 0, ',', '.'),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      render: (text) => `$${Highcharts.numberFormat(text, 0, ',', '.')}`,
    },
    {
      title: 'Documents',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
      render: (text) => Highcharts.numberFormat(text, 0, ',', '.'),
    },
  ];

  const detailColumns = [
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => Highcharts.numberFormat(text, 0, ',', '.'),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text) => `$${Highcharts.numberFormat(text, 0, ',', '.')}`,
    },
  ];

  const detailedData = (modelData) => {
    const aggregatedData = aggregateByColorAndSize(modelData);
    const sortedData = Object.keys(aggregatedData)
      .map(key => ({
        key,
        ...aggregatedData[key]
      }))
      .sort((a, b) => {
        const colorComparison = a.color.localeCompare(b.color);
        if (colorComparison === 0) {
          return a.size.localeCompare(b.size);
        }
        return colorComparison;
      });
    return sortedData;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data.length) {
    return <div>Loading...</div>;
  }

  const processCombinedChart = (data, office) => {
    let quantityPerDay = {};
    let totalAmountPerDay = {};

    if (office === 'Todas las Oficinas') {
      const dataByOffice = aggregateByOffice(data);
      const officeNames = Object.keys(dataByOffice);

      officeNames.forEach(officeName => {
        quantityPerDay[officeName] = data.filter(item => item['office_name'] === officeName).reduce((acc, item) => {
          const date = item['Fecha de Generacion v2'];
          acc[date] = (acc[date] || 0) + item['Quantity v2'];
          return acc;
        }, {});

        totalAmountPerDay[officeName] = data.filter(item => item['office_name'] === officeName).reduce((acc, item) => {
          const date = item['Fecha de Generacion v2'];
          acc[date] = (acc[date] || 0) + item['Total Amount v2'];
          return acc;
        }, {});
      });

      quantityPerDay['Aggregated'] = data.reduce((acc, item) => {
        const date = item['Fecha de Generacion v2'];
        acc[date] = (acc[date] || 0) + item['Quantity v2'];
        return acc;
      }, {});

      totalAmountPerDay['Aggregated'] = data.reduce((acc, item) => {
        const date = item['Fecha de Generacion v2'];
        acc[date] = (acc[date] || 0) + item['Total Amount v2'];
        return acc;
      }, {});

      const dates = Object.keys(quantityPerDay['Aggregated']);

      const series = officeNames.map(officeName => ({
        name: `Cantidad: ${officeName}`,
        data: dates.map(date => quantityPerDay[officeName][date] || 0),
        threshold:0,
        yAxis: 0,
        type: 'column'
      }));
      
      const totalAmountPerOffice = officeNames.map(officeName => ({
        name: `Venta: ${officeName}`,
        data: dates.map(date => totalAmountPerDay[officeName][date] || 0),
        threshold:0,
        yAxis: 1,
        type: 'column'
      }));

      series.push(...totalAmountPerOffice);

      return {
        chart: {
          zoomType: 'xy',
          alignThresholds: true,
          backgroundColor: theme === 'dark' ? '#2b2b2b' : '#ffffff'
        },
        title: {
          text: 'Quantity Sold and Total Amount Per Day',
          style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
        },
        xAxis: {
          categories: dates,
          crosshair: true,
          labels: { style: { color: theme === 'dark' ? '#ffffff' : '#000000' } }
        },
        yAxis: [
          {
            crosshair: true,
            title: {
              text: 'Quantity',
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
            },
            opposite: true,
            labels: {
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
              formatter: function () {
                return Highcharts.numberFormat(this.value, 0, ',', '.');
              }
            }
          },
          {
            crosshair: true,
            title: {
              text: 'Total Amount',
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
            },
            labels: {
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
              formatter: function () {
                return Highcharts.numberFormat(this.value, 0, ',', '.');
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          backgroundColor: theme === 'dark' ? '#333333' : '#ffffff',
          style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
          formatter: function () {
            return `<b>${this.x}</b><br/>${this.points.map(point => {
              const formattedValue = point.series.name === 'Total Amount'
                ? `$${Highcharts.numberFormat(point.y, 0, ',', '.')}`
                : Highcharts.numberFormat(point.y, 0, ',', '.');
              return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${formattedValue}</b>`;
            }).join('<br/>')}`;
          }
        },
        series: series
      };

    } else {
      quantityPerDay = data.reduce((acc, item) => {
        const date = item['Fecha de Generacion v2'];
        acc[date] = (acc[date] || 0) + item['Quantity v2'];
        return acc;
      }, {});

      totalAmountPerDay = data.reduce((acc, item) => {
        const date = item['Fecha de Generacion v2'];
        acc[date] = (acc[date] || 0) + item['Total Amount v2'];
        return acc;
      }, {});

      const dates = Object.keys(quantityPerDay);
      const quantities = Object.values(quantityPerDay);
      const totalAmounts = dates.map(date => totalAmountPerDay[date]);

      return {
        chart: {
          type: 'column',
          backgroundColor: theme === 'dark' ? '#2b2b2b' : '#ffffff'
        },
        title: {
          text: 'Quantity Sold and Total Amount Per Day',
          style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
        },
        xAxis: {
          categories: dates,
          crosshair: true,
          labels: { style: { color: theme === 'dark' ? '#ffffff' : '#000000' } }
        },
        yAxis: [
          {
            title: {
              text: 'Quantity',
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
            },
            opposite: true,
            labels: {
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
              formatter: function () {
                return Highcharts.numberFormat(this.value, 0, ',', '.');
              }
            }
          },
          {
            title: {
              text: 'Total Amount',
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' }
            },
            labels: {
              style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
              formatter: function () {
                return Highcharts.numberFormat(this.value, 0, ',', '.');
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          backgroundColor: theme === 'dark' ? '#333333' : '#ffffff',
          style: { color: theme === 'dark' ? '#ffffff' : '#000000' },
          formatter: function () {
            return `<b>${this.x}</b><br/>${this.points.map(point => {
              const formattedValue = point.series.name === 'Total Amount'
                ? `$${Highcharts.numberFormat(point.y, 0, ',', '.')}`
                : Highcharts.numberFormat(point.y, 0, ',', '.');
              return `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${formattedValue}</b>`;
            }).join('<br/>')}`;
          }
        },
        series: [
          {
            name: 'Quantity Sold',
            data: quantities,
            yAxis: 0,
            color: 'rgba(75,192,192,1)',
            type: 'column'
          },
          {
            name: 'Total Amount',
            data: totalAmounts,
            yAxis: 1,
            color: 'rgba(153,102,255,1)',
            type: 'column'
          }
        ]
      };
    }
  };

  const combinedChartOptions = processCombinedChart(filteredData, selectedOffice);
  const aggregatedData = Object.values(aggregateByModel(filteredData));
  const uniqueModels = Object.keys(aggregateByModel(data));
  const uniqueOffices = ['Todas las Oficinas', ...new Set(data.map(item => item.office_name))];

  return (
    <div>
      <h2>Documents Data</h2>
      <div>
        <RangePicker onChange={handleDateChange} />
        <Select
          showSearch
          optionFilterProp="label"
          allowClear
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ marginLeft: 10, width: 300 }} // Increase the width of the selector
          placeholder="Select Office"
          onChange={handleOfficeChange}
          options={uniqueOffices.map(office => ({ value: office, label: office }))}
          value={selectedOffice}
        />
        <Select
          showSearch
          optionFilterProp="label"
          allowClear
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ marginLeft: 10, width: 300 }} // Increase the width of the selector
          placeholder="Select Model"
          onChange={handleModelChange}
          options={availableModels.length ? availableModels.map(model => ({ value: model, label: model })): uniqueModels.map(model => ({ value: model, label: model }))}
          value={selectedModel}
        />
      </div>

      <Row gutter={16} style={{ margin: "20px 0px" }}>
        <Col span={8}>
          <Card title="Total Quantity" bordered={false} style={{ background: theme === 'dark' ? '#2b2b2b' : '#ffffff', color: theme === 'dark' ? '#ffffff' : '#000000' }}>
            {Highcharts.numberFormat(totalQuantity, 0, ',', '.')}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Amount" bordered={false} style={{ background: theme === 'dark' ? '#2b2b2b' : '#ffffff', color: theme === 'dark' ? '#ffffff' : '#000000' }}>
            ${Highcharts.numberFormat(totalAmount, 0, ',', '.')}
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Total Documents" bordered={false} style={{ background: theme === 'dark' ? '#2b2b2b' : '#ffffff', color: theme === 'dark' ? '#ffffff' : '#000000' }}>
            {Highcharts.numberFormat(totalDocuments, 0, ',', '.')}
          </Card>
        </Col>
      </Row>

      <HighchartsReact highcharts={Highcharts} options={combinedChartOptions} />

      <Table columns={columns} dataSource={aggregatedData} style={{ marginTop: 20}}/>

      {selectedModel && (
        <div style={{ marginTop: 20 }}>
          <h3>Details for Model: {selectedModel}</h3>
          <Table
            columns={detailColumns}
            dataSource={detailedData(filteredData.filter(item => parseModel(item['code']) === selectedModel))}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentsChart;