import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter } from 'react-router-dom'

import MainLayout from '@/layout/MainLayout'
import LoginPage from '@/pages/Login'
import LoginForgotPassword from '@/pages/LoginForgotPassword'
import LoginPasswordReset from '@/pages/LoginPasswordReset'
import NotFoundPage from '@/pages/NotFound'

import PrivateRoute from '@/components/PrivateRoute'
import privateRoutes from '@/router/private'

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '/forgot-password',
    element: <LoginForgotPassword />
  },
  {
    path: '/password/reset/confirm/:uid/:token/',
    element: <LoginPasswordReset />
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <MainLayout />
      </PrivateRoute>
    ),
    children: privateRoutes
  },
  { path: '*', element: <NotFoundPage /> }
])

export default router
