import { Table } from 'antd';
import { formatValue } from '@/Utils';

const SimpleComparisonTable = ({ data, title }) => {
    const columns = [
        {
            title: title,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Año pasado',
            dataIndex: 'last_year',
            key: 'last_year',
        },
        {
            title: 'Año actual',
            dataIndex: 'current_year',
            key: 'current_year',
        },
        {
            title: 'Desviación',
            dataIndex: 'versus',
            key: 'versus',
        },
    ];

    const allKeys = new Set([...Object.keys(data.last_year), ...Object.keys(data.current_year)]);
    const tableData = Array.from(allKeys).map(key => ({
        key,
        name: key,
        last_year: formatValue(data.last_year[key] || 0),
        current_year: formatValue(data.current_year[key] || 0),
        versus: formatValue((data.versus[key] || 0) * 100, 2, 2) + '%',
    }));

    return <Table columns={columns} dataSource={tableData} pagination={false} />;
};

export default SimpleComparisonTable;