import { useEffect, useState } from 'react';
import { Table, Typography, Button, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { fetchStockDataPerOffice, getStockExcelFile } from '@/requests/dataService';

const { Title } = Typography;
const { Search } = Input;

const StockPerOffice = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchStockDataPerOffice();
        const transformedData = transformData(response);
        setData(transformedData);
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const transformData = (rawData) => {
    const productMap = {};

    rawData.forEach(item => {
      if (!productMap[item.product_sku]) {
        productMap[item.product_sku] = {
          sku: item.product_sku,
          offices: {
            COSTANERA: { current_stock: null, ideal_stock: null, recommended_reposition: null },
            ARAUCARIA: { current_stock: null, ideal_stock: null, recommended_reposition: null },
            WEB: { current_stock: null },
            MAYORISTA: { current_stock: null }
          }
        };
      }

      let office = item.office.toUpperCase();
      if (office === 'FABRICA WEB') {
        office = 'WEB';
      }
      if (!productMap[item.product_sku].offices[office]) {
        productMap[item.product_sku].offices[office] = {
          current_stock: null,
          ideal_stock: null,
          recommended_reposition: null
        };
      }

      productMap[item.product_sku].offices[office].current_stock = item.quantity_current;
      productMap[item.product_sku].offices[office].ideal_stock = item.quantity;
      productMap[item.product_sku].offices[office].recommended_reposition = item.recommended_reposition;
    });

    return Object.values(productMap);
  };

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await getStockExcelFile();

      // Create blob from response data
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create download link
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'stock_por_oficina.xlsx');

      // Trigger download
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading excel file:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = data.filter((product) =>
    product.sku.toLowerCase().includes(searchText)
  );

  const getColumnStyle = (index) => ({
    background: index % 2 === 0 ? '#f0f0f0' : '#ffffff',
  });

  const columns = [
    {
      title: 'SKU',
      className: "white_style_col",
      dataIndex: 'sku',
      key: 'sku',
      fixed: 'left',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Costanera',
      className: "grey_style_col",
      children: [
        {
          title: 'Act.',
          className: "grey_style_col",
          dataIndex: ['offices', 'COSTANERA', 'current_stock'],
          key: 'costanera_current_stock',
          align: 'right',
        },
        {
          title: 'Ideal',
          className: "grey_style_col",
          dataIndex: ['offices', 'COSTANERA', 'ideal_stock'],
          key: 'costanera_ideal_stock',
          align: 'right',
        },
        {
          title: 'Repos.',
          className: "grey_style_col",
          dataIndex: ['offices', 'COSTANERA', 'recommended_reposition'],
          key: 'costanera_recommended_reposition',
          align: 'right',
        },
      ],
    },
    {
      title: 'Araucaria',
      className: "white_style_col",
      children: [
        {
          title: 'Act.',
          className: "white_style_col",
          dataIndex: ['offices', 'ARAUCARIA', 'current_stock'],
          key: 'araucaria_current_stock',
          align: 'right',
          onCell: () => getColumnStyle(1),
        },
        {
          title: 'Ideal',
          className: "white_style_col",
          dataIndex: ['offices', 'ARAUCARIA', 'ideal_stock'],
          key: 'araucaria_ideal_stock',
          align: 'right',
          onCell: () => getColumnStyle(1),
        },
        {
          title: 'Repos.',
          className: "white_style_col",
          dataIndex: ['offices', 'ARAUCARIA', 'recommended_reposition'],
          key: 'araucaria_recommended_reposition',
          align: 'right',
          onCell: () => getColumnStyle(1),
        },
      ],
    },
    {
      title: 'Web',
      className: "grey_style_col",
      children: [
        {
          title: 'Act.',
          className: "grey_style_col",
          dataIndex: ['offices', 'WEB', 'current_stock'],
          key: 'web_current_stock',
          align: 'right',
          onCell: () => getColumnStyle(2),
        },
      ],
    },
    {
      title: 'Mayorista',
      className: "white_style_col",
      children: [
        {
          title: 'Act.',
          className: "white_style_col",
          dataIndex: ['offices', 'MAYORISTA', 'current_stock'],
          key: 'mayorista_current_stock',
          align: 'right',
        },
      ],
    },
  ];

  return (
    <div>
      <Title level={2}>Stock Por Oficina</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Search
          placeholder="Buscar por SKU"
          onSearch={handleSearch}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
          disabled={loading}
        />
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={handleDownload}
          disabled={loading}
        >
          Descargar Excel
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData}
        loading={loading}
        rowKey="sku"
        scroll={{ x: 'max-content' }}
        components={{
          header: {
            cell: ({ children, ...restProps }) => (
              <th {...restProps} style={{ textAlign: 'center' }}>
                {children}
              </th>
            ),
          },
        }}
      />
    </div>
  );
};

export default StockPerOffice;
