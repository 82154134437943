import { client as axiosClient } from '@/requests/clients'

const request = axiosClient({
  timeout: 60 * 2 * 1000 // Waiting 2 min
  // there's an interceptor that adds the token to the request in src/components/RequestInterceptor/index.tsx
})

export async function loginRequest(username, password) {
  const response = await request.post('/auth/login/', {
    username,
    password
  })
  return response
}

export async function resetPasswordRequest(email) {
  const response = await request.post('/auth/password/reset/', { email })
  return response
}

export async function resetPasswordConfirmRequest(
  uid,
  token,
  new_password1,
  new_password2
) {
  const response = await request.post('/auth/password/reset/confirm/', {
    uid,
    token,
    new_password1,
    new_password2
  })
  return response
}

export async function refreshAccessTokenRequest(refreshToken) {
  const response = await request.post('/auth/refresh/', {
    refresh: refreshToken
  })
  return response
}
