import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, Input, Button, Typography, Card } from 'antd'
// import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import AuthContext from '@/context/AuthContext'

const { Title, Text } = Typography

const LoginPage = () => {
  const { login, isAuthenticated } = useContext(AuthContext)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/company')
    }
  }, [isAuthenticated, navigate])

  const handleLogin = async (values) => {
    const { username, password } = values

    if (!username.trim()) {
      toast.error('Por favor, introduce un nombre de usuario')
      return
    }

    if (!password.trim()) {
      toast.error('Por favor, introduce una contraseña')
      return
    }

    const success = await login(username, password)
    if (success) {
      setTimeout(() => {
        navigate('/company')
      }, 5)
    }
  }

  return (
    <CenteredBox>
      <Card
        style={{
          minWidth: '35%',
          backgroundColor: 'white',
          padding: '32px',
          borderRadius: '8px',
          boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Form layout="vertical" onFinish={handleLogin}>
          {/* <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} /> */}
          <Title level={4}>Iniciar sesión</Title>
          <Text>Ingrese tus datos para acceder al sistema</Text>
          <Form.Item
            label="Nombre de usuario"
            name="username"
            rules={[{ required: true, message: 'Por favor, introduce un nombre de usuario' }]}
          >
            <Input autoComplete="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Por favor, introduce una contraseña' }]}
          >
            <Input.Password autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Entrar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="link" onClick={() => navigate('/forgot-password')} style={{ width: '100%' }}>
              ¿Olvidaste tu contraseña?
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </CenteredBox>
  )
}

export default LoginPage
