import axios from 'axios'
import { config } from '@/Constants';

export const USER_API = config.url.BASE_URL;

export const client = (options) => {
  const defaultOptions = {
    baseURL: USER_API,
    timeout: 60 * 2 * 1000, // Waiting 2 min
    ...options
    // there's an interceptor that adds the token to the request in src/components/RequestInterceptor/index.tsx
  }

  return {
    get: (url, options) =>
      axios.get(url, { ...defaultOptions, ...options }),
    post: (
      url,
      data,
      options
    ) =>
      axios.post(url, data, { ...defaultOptions, ...options }),
    put: (
      url,
      data,
      options
    ) =>
      axios.put(url, data, { ...defaultOptions, ...options }),
    delete: (
      url,
      options
    ) =>
      axios.delete(url, { ...defaultOptions, ...options })
  }
}

export const clientDownloadFile = (
  url,
  params = {},
  timeout = 60 * 10 * 1000 // 10 minutes
) => {
  // options should have url and params values
  return axios({
    baseURL: USER_API,
    method: 'get',
    responseType: 'blob',
    timeout,
    url,
    params
  })
}

export const postClientDownloadFile = (
  url,
  data = {},
  timeout = 60 * 10 * 1000 // 10 minutes
) => {
  return axios({
    baseURL: USER_API,
    method: 'post',
    responseType: 'blob',
    timeout,
    url,
    data
  })
}

export const clientUploadFile = (
  url,
  file,
  customParams = {},
  responseType = 'json',
  timeout = 600000 // 10 minutes
) => {
  const formData = new FormData()
  formData.append('file', file)
  for (const [key, value] of Object.entries(customParams)) {
    formData.append(key, value)
  }
  return axios({
    baseURL: USER_API,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    responseType,
    url,
    timeout
  })
}

export const clientUploadFiles = (
  url,
  files,
  pages = [],
  customParams = {},
  responseType = 'json',
  timeout = 600000 // 10 minutes
) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })
  pages.forEach((page) => {
    formData.append('pages', page)
  })
  for (const [key, value] of Object.entries(customParams)) {
    formData.append(key, value)
  }
  return axios({
    baseURL: USER_API,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    responseType,
    url,
    timeout
  })
}
