import { memo, useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { Layout } from 'antd'
import SideBar from '@/components/Sidebar'

const { Content } = Layout

const MemoizedAppContentRouter = memo(Outlet)

const MainLayout = () => {
  const isLgScreen = window.innerWidth >= 1200

  const [collapsed, setCollapsed] = useState(!isLgScreen);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setCollapsed(false)
      } else {
        setCollapsed(true)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Layout style={{ display: 'flex', minHeight: '100vh', minWidth: '100vw' }}>
      <SideBar toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
      <Content style={{ paddingTop: '15px', backgroundColor: '#F5F5F5' }}>
        <div style={{ marginTop: '8px', marginBottom: '0', padding: '0 24px' }}>
          <MemoizedAppContentRouter />
        </div>
      </Content>
    </Layout>
  )
}

export default MainLayout
