import { useState } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Form, Input, Typography, Card } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
// import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import { resetPasswordConfirmRequest } from '@/requests/auth'

const { Title } = Typography

const LoginPasswordResetPage = () => {
  const { uid, token } = useParams()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate()

  const handleResetPassword = async () => {
    if (!uid || !token) {
      toast.error('Enlace de restablecimiento inválido')
      return
    }
    if (password !== confirmPassword) {
      toast.error('¡Las contraseñas no coinciden!')
      return
    }
    try {
      const response = await resetPasswordConfirmRequest(uid, token, password, confirmPassword)
      if (response.status !== 200) {
        toast.error('¡Error al restablecer la contraseña!')
        return
      }
      navigate('/')
      toast.success('¡Restablecimiento de contraseña exitoso!')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data?.extra?.fields?.new_password2
            ? error.response.data.extra.fields.new_password2[0]
            : error.response.data?.message
          toast.error(errorMessage)
        } else {
          console.error(error.response)
        }
      } else {
        console.error(error)
      }
    }
  }

  return (
    <CenteredBox>
      <Card
        style={{
          minWidth: '35%',
          backgroundColor: 'white',
          padding: '32px',
          borderRadius: '8px',
          boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Link to="/" style={{ marginBottom: '16px', display: 'inline-block' }}>
          <Button type="link" icon={<ArrowLeftOutlined />}>
            Volver al inicio
          </Button>
        </Link>
        <Form
          layout="vertical"
          onFinish={(e) => {
            e.preventDefault()
            handleResetPassword()
          }}
        >
          {/* <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} /> */}
          <Title level={4}>Restablecer contraseña</Title>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Por favor, introduce una contraseña' }]}
          >
            <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            label="Confirmar contraseña"
            name="confirmPassword"
            rules={[
              { required: true, message: 'Por favor, confirma tu contraseña' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('¡Las contraseñas no coinciden!'))
                },
              }),
            ]}
          >
            <Input.Password value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} autoComplete="new-password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} onClick={handleResetPassword}>
              Restablecer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </CenteredBox>
  )
}

export default LoginPasswordResetPage
