import { client as axiosClient } from './clients'
import { clientUploadFile, clientDownloadFile } from './clients'

const request = axiosClient({
  timeout: 60 * 6 * 1000 // Waiting 2 min
  // there's an interceptor that adds the token to the request in src/components/RequestInterceptor/index.tsx
})

export const fetchDocumentsData = async () => {
  try {
    const response = await request.get("/product/process-data/");
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
};

export const fetchSalesStatisticData = async () => {
  try {
    const response = await request.get("/product/sales-statistics-data/");
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
};

export const getAllFilesFunction = async (type) => {
  try {
    const response = await request.get(`/product/files/${type}/`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export const uploadFileFunction = async (file, fileType) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    console.log('Sending request to:', `/product/upload/${fileType}/`);
    return clientUploadFile(`/product/upload/${fileType}/`, file);
  } catch (error) {
    console.error('Error during file upload:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    }
    throw new Error('Error uploading file');
  }
};

export const handleLoginFunction = async (credentials) => {
  console.log(credentials)
  try {
    const response = await request.post(`/users/login/test/`, credentials);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export const fetchStockDataPerOffice = async () => {
  try {
    const response = await request.get(`/product/get-stock/`);
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export const getStockExcelFile = async () => {
  try {
    const response = await clientDownloadFile("/product/get-stock/excel/");
    return response.data;
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export const getClientsFromMayorista = async () => {
  try {
    const response = await request.get("/product/get-clients-from-mayorista/");
    return response.data;
  } catch (error) {
    throw new Error('Error fetching client data');
  }
};

export const updateClientSalesVisibility = async (data) => {
  try {
    const response = await request.post("/product/update-client-sales-visibility/", data);
    return response.data;
  } catch (error) {
    throw new Error('Error updating client sales visibility');
  }
};
