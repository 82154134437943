import { useEffect, useState } from 'react';
import { Row, Col, Card, Statistic, Spin } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { fetchSalesStatisticData } from '@/requests/dataService';
import ReportTable from '@/components/ReportTable';
import SimpleComparisonTable from '@/components/SpecificSalesReportTable';

const YearComparisonTable = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        fetchSalesStatisticData().then(response => {
          setData(response);
          setLoading(false);
        });
      } catch (error) {
        console.error("There was an error fetching the data!", error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) {
    return <Spin size="large" />;
  }

  const generateChartOptions = (officeData) => {
    return {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Sales Comparison'
      },
      xAxis: {
        categories: ['Neto', 'Cantidad', 'Neto Anual']
      },
      yAxis: [
        {
          title: {
            text: '$'
          },
          opposite: false
        },
        {
          title: {
            text: '#'
          },
          opposite: true
        }
      ],
      series: [
        {
          name: 'Año Pasado ($)',
          data: [
            officeData['last_year']['Total Amount'],
            null,
            officeData['last_year']['Total of Year']
          ],
          yAxis: 0
        },
        {
          name: 'Año Actual ($)',
          data: [
            officeData['curr_year']['Total Amount'],
            null,
            officeData['curr_year']['Total of Year']
          ],
          yAxis: 0
        },
        {
          name: 'Año Pasado (#)',
          data: [
            null,
            officeData['last_year']['Quantity'],
            null
          ],
          yAxis: 1
        },
        {
          name: 'Año Actual (#)',
          data: [
            null,
            officeData['curr_year']['Quantity'],
            null
          ],
          yAxis: 1
        }
      ]
    };
  };
  console.log(data);
  return (
    <div className="site-card-wrapper">
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        {Object.keys(data.sales).map((officeName) => (
          <Col span={8} key={officeName}>
            <Card title={officeName} bordered={false}>
              <Statistic
                title="Variation"
                value={data.sales[officeName]["variation"]["Total Amount Net Real"]}
                precision={2}
                valueStyle={{ color: data.sales[officeName]["variation"]["Total Amount Net Real"] >= 0 ? '#3f8600' : '#cf1322' }}
                suffix="%"
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={generateChartOptions(data.sales[officeName])}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <ReportTable data={data.sales} />
      <Row gutter={16} style={{ margin: "20px 0px" }}>
        <Col span={12}>
          <Card title="Ventas por Tipo" bordered={false}>
            <SimpleComparisonTable data={data.sells_by_type} title="Tipo" />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Ventas por canal de Marketplace" bordered={false}>
            <SimpleComparisonTable data={data.sells_by_marketplace_office} title="Tienda" />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default YearComparisonTable;
