import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Form, Input, Typography, Card } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
// import bciLogo from '@/assets/bci_logo.png'
import CenteredBox from '@/components/CenteredBox'
import { resetPasswordRequest } from '@/requests/auth'

const { Title, Text } = Typography

const LoginForgotPasswordPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [lastRequestTime, setLastRequestTime] = useState(0)

  const handleSendLink = async () => {
    const now = Date.now()
    if (now - lastRequestTime < 60000) {
      toast.error('Por favor, espera un minuto antes de solicitar otro restablecimiento de contraseña')
      return
    }
    setLastRequestTime(now)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!email.trim()) {
      toast.error('Por favor, introduce un correo electrónico')
      return
    }

    if (!emailPattern.test(email)) {
      toast.error('Por favor, introduce un correo electrónico válido')
      return
    }

    const response = await resetPasswordRequest(email)
    if (response.status !== 200) {
      toast.error('Error sending link!')
      return
    }
    toast.success('¡Enlace enviado a tu correo electrónico!', { autoClose: false })
    navigate('/')
  }

  return (
    <CenteredBox>
      <Card
        style={{
          minWidth: '35%',
          backgroundColor: 'white',
          padding: '32px',
          borderRadius: '8px',
          boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
        }}
      >
        <Link to="/" style={{ marginBottom: '16px', display: 'inline-block' }}>
          <Button type="link" icon={<ArrowLeftOutlined />}>
            Volver al inicio
          </Button>
        </Link>
        <Form layout="vertical" onFinish={handleSendLink}>
          {/* <img src={bciLogo} alt="bci logo" style={{ maxWidth: '64px' }} /> */}
          <Title level={4}>¿Olvidaste la contraseña?</Title>
          <Text>Ingresa tu mail y te llegará a tu correo un enlace para reestablecer tu contraseña</Text>
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[
              { required: true, message: 'Por favor, introduce un correo electrónico' },
              { type: 'email', message: 'Por favor, introduce un correo electrónico válido' },
            ]}
          >
            <Input autoComplete="email" onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Enviar enlace
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </CenteredBox>
  )
}

export default LoginForgotPasswordPage
