import { Table } from 'antd';
import PropTypes from 'prop-types';
import { formatValue } from '@/Utils';

const columns = [
  {
    title: 'Oficina',
    dataIndex: 'office',
    key: 'office',
  },
  {
    title: 'Presupuesto Mes',
    className: 'budget',
    children: [
      { title: '%', dataIndex: 'estimated_sales_diff', key: 'estimated_sales_diff', className: 'budget' },
      { title: 'Mes', dataIndex: 'estimated_sales_total', key: 'estimated_sales_total', className: 'budget' },
      { title: 'A la fecha', dataIndex: 'estimated_sales_curr', key: 'estimated_sales_curr', className: 'budget' },
    ],
  },
  {
    title: 'Venta 2024 a la fecha',
    className: 'sales-2024',
    children: [
      { title: 'Neto', dataIndex: 'curr_year_neto', key: 'curr_year_neto', className: 'sales-2024' },
      { title: 'Cantidad', dataIndex: 'curr_year_quantity', key: 'curr_year_quantity', className: 'sales-2024' },
      { title: 'Costo', dataIndex: 'curr_year_cost', key: 'curr_year_cost', className: 'sales-2024' },
      { title: 'Margen', dataIndex: 'curr_year_margin', key: 'curr_year_margin', className: 'sales-2024' },
    ],
  },
  {
    title: 'Total 2023',
    dataIndex: 'total_2023',
    key: 'total_2023',
    className: 'sales-2023',
  },
  {
    title: 'Venta 2023 a la fecha (AA)',
    className: 'sales-2023',
    children: [
      { title: 'Neto', dataIndex: 'last_year_neto', key: 'last_year_neto', className: 'sales-2023' },
      { title: 'Cantidad', dataIndex: 'last_year_quantity', key: 'last_year_quantity', className: 'sales-2023' },
      { title: 'Costo', dataIndex: 'last_year_cost', key: 'last_year_cost', className: 'sales-2023' },
      { title: 'Margen', dataIndex: 'last_year_margin', key: 'last_year_margin', className: 'sales-2023' },
    ],
  },
  {
    title: 'Desviaciones',
    className: 'deviations',
    children: [
      { title: 'Neto Real/AA', dataIndex: 'neto_real_aa', key: 'neto_real_aa', className: 'deviations' },
      { title: 'Mg Real/AA', dataIndex: 'mg_real_aa', key: 'mg_real_aa', className: 'deviations' },
      { title: 'Neto Real/PP', dataIndex: 'neto_real_pp', key: 'neto_real_pp', className: 'deviations' },
    ],
  },
];


const ReportTable = ({ data }) => {

  const tableData = Object.keys(data).map((officeName, index) => ({
    key: index,
    office: officeName,
    estimated_sales_diff: data[officeName].estimated_sales['Difference'] ? formatValue(data[officeName].estimated_sales['Difference'] * 100) + '%' : '0%',
    estimated_sales_total: formatValue(data[officeName].estimated_sales['Total Month']),
    estimated_sales_curr: formatValue(data[officeName].estimated_sales['Until Current Day']),
    curr_year_neto: formatValue(data[officeName].curr_year['Total Amount']),
    curr_year_quantity: formatValue(data[officeName].curr_year['Quantity']),
    curr_year_cost: formatValue(data[officeName].curr_year['Cost']),
    curr_year_margin: formatValue(data[officeName].curr_year['Margin'] * 100) + '%',
    total_2023: formatValue(data[officeName].last_year['Total of Year']),
    last_year_neto: formatValue(data[officeName].last_year['Total Amount']),
    last_year_quantity: formatValue(data[officeName].last_year['Quantity']),
    last_year_cost: formatValue(data[officeName].last_year['Cost']),
    last_year_margin: formatValue(data[officeName].last_year['Margin'] * 100) + '%',
    neto_real_aa: data[officeName].variation['Total Amount Net Real'] !== null ?
      formatValue(data[officeName].variation['Total Amount Net Real'] * 100, 2, 2) + '%' : '',
    mg_real_aa: data[officeName].variation['Mg Real/AA'] !== null ?
      formatValue(data[officeName].variation['Mg Real/AA'] * 100, 2, 2) + '%' : '',
    neto_real_pp: data[officeName].variation['Neto Real/PP'] !== null ?
      formatValue(data[officeName].variation['Neto Real/PP'] * 100, 2, 2) + '%' : '',
  }));


  return <Table columns={columns} dataSource={tableData} pagination={false} />;
};

ReportTable.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.shape({
      estimated_sales: PropTypes.shape({
        'Difference': PropTypes.number,
        'Total Month': PropTypes.number,
        'Until Current Day': PropTypes.number
      }),
      curr_year: PropTypes.shape({
        'Total Amount': PropTypes.number,
        'Quantity': PropTypes.number,
        'Cost': PropTypes.number,
        'Margin': PropTypes.number,
        'Total of Year': PropTypes.number,
      }),
      last_year: PropTypes.shape({
        'Total Amount': PropTypes.number,
        'Quantity': PropTypes.number,
        'Cost': PropTypes.number,
        'Margin': PropTypes.number,
        'Total of Year': PropTypes.number,
      }),
      variation: PropTypes.shape({
        'Total Amount Net Real': PropTypes.number,
        'Mg Real/AA': PropTypes.number,
        'Neto Real/PP': PropTypes.number,
      }),
    })
  ).isRequired,
};

export default ReportTable;