const prod = {
	url: {
		BASE_URL: 'https://backfinanzasgdf.gianidafirenze.cl/',
		AUTH_URL: 'https://backfinanzasgdf.gianidafirenze.cl/',
	},
};

const dev = {
	url: {
		BASE_URL: 'http://localhost:8000/',
		AUTH_URL: 'http://localhost:3090/',
	},
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;