import { useContext } from 'react'

import { Navigate } from 'react-router-dom'

import AuthContext from '@/context/AuthContext'

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, isAuthenticated } = useContext(
    AuthContext
  )

  if (isLoggedIn && !isAuthenticated) {
    return <div>Loading...</div>
  }
  return isLoggedIn ? children : <Navigate to="/" />
}

export default PrivateRoute
