import { Link } from 'react-router-dom'
import { Button, Typography, Card } from 'antd'
import CenteredBox from '@/components/CenteredBox'
// import monoPng from '@/assets/monoamarillo.png'

const { Title } = Typography

const NotFoundPage = () => {
  return (
    <div>
      <CenteredBox>
        <Card style={{ textAlign: 'center', padding: '32px', borderRadius: '8px' }}>
          <Title level={3}>Error 404: Página no encontrada</Title>
          <hr className="my-2" />
          <div className="lead">
            <img
              // src={monoPng}
              alt="Logo BCI Asset Management"
              style={{ height: 255, width: 180 }}
            />
          </div>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button type="primary" style={{ marginTop: '16px' }}>
              Ir al inicio
            </Button>
          </Link>
        </Card>
      </CenteredBox>
    </div>
  )
}

export default NotFoundPage
