import AuthContext from '@/context/AuthContext'
import axios from 'axios'
import { useContext, useEffect } from 'react'

const RequestInterceptor = ({ children }) => {
  const { logout, refreshAccessToken } = useContext(
    AuthContext
  )

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token')
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.request.eject(requestInterceptor)
    }
  }, [])

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        // this will only be called if the request fails
        // it'll try to refresh the access token if it's a 403 error and the error code is token_not_valid
        // if the refresh token is expired, it'll log the user out
        const originalRequest = error.config
        const errorCode = (error.response?.data)?.code
        if (
          error.response &&
          error.response.status === 403 &&
          errorCode === 'token_not_valid' &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const accessToken = await refreshAccessToken()
          if (accessToken && originalRequest.headers?.Authorization) {
            originalRequest.headers.Authorization = 'Bearer ' + accessToken
            return axios(originalRequest)
          }
          logout()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axios.interceptors.response.eject(responseInterceptor)
    }
  }, [logout, refreshAccessToken])

  return <>{children}</>
}

export default RequestInterceptor
