import '@/App.css';
import { Provider } from 'jotai';
import { RouterProvider } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import ThemeProvider from '@/ThemeProvider'

import RequestInterceptor from '@/components/RequestInterceptor'
import AuthProvider from '@/context/AuthProvider'
import router from '@/router'
import 'react-toastify/dist/ReactToastify.min.css'

function App() {

  return (
    <div className="App">
      <Provider>
        <ThemeProvider>
          <AuthProvider>
            <RequestInterceptor>
              <RouterProvider router={router} />
            </RequestInterceptor>
          </AuthProvider>
          <ToastContainer position="bottom-center" autoClose={10000} />
        </ThemeProvider>
      </Provider>
    </div>
  );
}
{/* <Router>
  <Layout style={{ minHeight: '100vh', width: '100vw' }}>
    {isAuthenticated && <Navbar onLogout={handleLogout} />}
    <Content style={{ padding: '0 50px', marginTop: 64 }}>
      <Routes>
        <Route exact path="/" element={isAuthenticated ? <MainPage /> : <Navigate to="/login" />} />
        <Route path="/metricas" element={isAuthenticated ? <DocumentsChart /> : <Navigate to="/login" />} />
        <Route path="/report" element={isAuthenticated ? <YearComparisonTable /> : <Navigate to="/login" />} />
        <Route path="/admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage onLogin={handleLogin} />} />
      </Routes>
    </Content>
    <Footer />
  </Layout>
</Router> */}

export default App;