import { useState, useEffect, useContext } from 'react'
import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom';
import AuthContext from '@/context/AuthContext'
import privateRoutes from '@/router/private'

const Sidebar = () => {
  const { logout } = useContext(AuthContext)
  const location = useLocation(); // To get the current URL
  const [current, setCurrent] = useState('');

  // Sync current tab with the URL path
  useEffect(() => {
    const matchingRoute = privateRoutes.find(route => route.path === location.pathname);
    if (matchingRoute) {
      setCurrent(matchingRoute.key); // Set the menu item key based on the path
    } else {
      setCurrent('1'); // Default to the first item or home
    }
  }, [location.pathname]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      mode="horizontal"
      onClick={onClick}
      selectedKeys={[current]} // Highlight the current tab
    >
      {privateRoutes.map((item) => (
        <Menu.Item key={item.key}>
          <Link to={item.path}>{item.label}</Link>
        </Menu.Item>
      ))}
      <Menu.Item key="logout" onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  )
}

export default Sidebar;
