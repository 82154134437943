import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { loginRequest, refreshAccessTokenRequest } from '@/requests/auth'
import AuthContext from '@/context/AuthContext'

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [token, setToken] = useState(() =>
    localStorage.getItem('token')
  )
  const [refreshToken, setRefreshToken] = useState(() =>
    localStorage.getItem('refreshToken')
  )
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user')
    return storedUser ? JSON.parse(storedUser) : null
  })
  const [groups, setGroups] = useState(() => {
    const unformattedStoredGroups = localStorage.getItem('groups')
    const storedGroups = unformattedStoredGroups
      ? unformattedStoredGroups.split(',')
      : []
    return storedGroups
  })

  useEffect(() => {
    const token = localStorage.getItem('token')
    const user = localStorage.getItem('user')
    const refreshToken = localStorage.getItem('refreshToken')
    const unformattedGroups = localStorage.getItem('groups')
    const groups = unformattedGroups ? unformattedGroups.split(',') : []

    if (token && refreshToken && user) {
      const isRefreshTokenValid = validateToken(refreshToken)

      if (isRefreshTokenValid) {
        setToken(token)
        setUser(JSON.parse(user))
        setRefreshToken(refreshToken)
        setIsAuthenticated(true)
        setGroups(groups)
      } else {
        // Token expirado, limpiar localStorage y estado
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('groups')
        setToken(null)
        setUser(null)
        setIsAuthenticated(false)
        setGroups([])
      }
    }
  }, [])

  const validateToken = (token) => {
    try {
      const decodedToken = jwtDecode(token)
      // Obtenemos la fecha de expiración del token en segundos
      const expirationTimeInSeconds = decodedToken.exp
      // Convertimos a milisegundos y comparamos con la fecha actual
      const currentTime = Date.now() / 1000
      return expirationTimeInSeconds > currentTime
    } catch (error) {
      console.error('Error al decodificar el token:', error)
      return false
    }
  }

  const login = async (username, password) => {
    try {
      const response = await loginRequest(username, password)
      if (response.data.access) {
        // getting data
        localStorage.setItem('token', response.data.access)
        localStorage.setItem('refreshToken', response.data.refresh)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        const groupsArray = Array.isArray(response.data.groups)
          ? response.data.groups
          : [response.data.groups]
        localStorage.setItem('groups', groupsArray)
        // setting data to state
        setToken(response.data.access)
        setRefreshToken(response.data.refresh)
        setUser(response.data.user)
        setGroups(groupsArray)
        setIsAuthenticated(true)
        return true
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        if (error.response && error.response.status === 401) {
          // Unauthorized
          logout()
        }
        if (error.response && error.response.status === 400) {
          const errorMessage = error.response.data?.extra?.fields
            ?.non_field_errors
            ? error.response.data.extra.fields.non_field_errors[0]
            : error.response.data?.message
          toast.error(errorMessage)
        }
      } else {
        console.error(error)
      }
    }
    return false
  }

  const refreshAccessToken = async () => {
    try {
      if (!refreshToken) {
        return ''
      }
      const response = await refreshAccessTokenRequest(refreshToken)
      if (response.data.access) {
        localStorage.setItem('token', response.data.access)
        setToken(response.data.access)
        return response.data.access
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response)
        if (error.response && error.response.status === 401) {
          // Unauthorized
          logout()
        }
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data?.message)
        }
      } else {
        console.error(error)
      }
    }
    return ''
  }

  const logout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('user')
    setToken(null)
    setRefreshToken(null)
    setUser(null)
    setIsAuthenticated(false)
  }

  const isLoggedIn = !!token

  return (
    <AuthContext.Provider
      value={{
        token,
        isLoggedIn,
        isAuthenticated,
        user,
        groups,
        setToken,
        login,
        logout,
        validateToken,
        refreshAccessToken
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
