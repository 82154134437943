import { Layout } from 'antd'

const { Content } = Layout

const CenteredBox = ({ children }) => {
  return (
    <Content>
      {children}
    </Content>
  )
}

export default CenteredBox
