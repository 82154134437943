import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { ConfigProvider } from 'antd';
import { themeAtom } from '@/atoms';
import { theme } from 'antd';

const { defaultAlgorithm, darkAlgorithm } = theme;

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useAtom(themeAtom);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleThemeChange = (e) => {
      setTheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleThemeChange);
    return () => {
      mediaQuery.removeEventListener('change', handleThemeChange);
    };
  }, [setTheme]);

  return (
    <ConfigProvider theme={{ algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm }}>
      <div className={theme}>
        {children}
      </div>
    </ConfigProvider>
  );
};

export default ThemeProvider;