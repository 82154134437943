const MainPage = () => {
  return (
    <div>
      <h1>Bsale Dashboard</h1>
      <p>Welcome to the Bsale Dashboard. Here you can see relevant information.</p>
      {/* Additional relevant info can be added here */}
    </div>
  );
};

export default MainPage;
